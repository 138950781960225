<template>
  <div ref="sidebar" class="sidebar h-100" :style="sidebarWidth">
    <div id="sidebarWidth" class="sidebar-inner h-100">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// import simplebar from "simplebar-vue";

export default {
  name: "Sidebar",
  components: {
    // simplebar
  },
  computed: {
    ...mapGetters(["sidebarState"]),
    sidebarWidth: function () {
      return {
        width: this.sidebarState ? "37em" : "0",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: #f1f1f1 !important;
  border-top-left-radius: 1.2rem;
  overflow: hidden;
  transition: width 0.4s;
  z-index: 1;

  .sidebar-inner {
    width: 37em;
  }

  .scroller {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
</style>
